/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { clearTokenFromCookie, getUserTypeFromToken, setTokenInCookie } from '../_helpers/cookie'
import { History } from '../_helpers/history'
import { handleMessages } from '../_helpers/utilities'
import customerUserService from '../_services/customerUserService'
import userService from '../_services/userService'
import { RootState } from '../_store/store'
import {
  IDeleteAccountFormData,
  IForgetPasswordFormData,
  INewPasswordData,
  ITokenExpiryCheck,
  IUpdateDetails,
  IUserState,
} from './../_models/userInterface'
import { errorAlert } from './alertSlice'

import { getBusinessDetails } from './businessSlice'
import { startLoader, stopLoader } from './loaderSlice'
import { getPricingLimits } from './pricingSlice'
import { errorToast, successToast } from './toastSlice'
import { businessVoyagerConfiguration } from './voyagerSlice'
// import { RESET_STATE } from '../_store/actions'

const initialState: IUserState = {
  // loader: false,
  userData: {},
  userProfileState: {},
  forgotPasswordMailAddress: '',
  userProfileInformation: {},
  activePlan: true,
}
// USER DETAILS

export const userDetails = createAsyncThunk(
  '/userDetails',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    dispatch(getProfileInformation())
    try {
      const response = await userService.getUserDetails()
      dispatch(businessVoyagerConfiguration())
      setTokenInCookie(response?.data?.token)
      if (response?.data?.type === 'business') {
        dispatch(getPricingLimits({ activePlan: response?.active_plan }))
        // Added for getting country code for the business
        // Can remove once we integrate international payment gateway
        dispatch(getBusinessDetails())
        // end
      }

      dispatch(stopLoader())

      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// USER PROFILE INFORMATION

export const getProfileInformation = createAsyncThunk(
  '/profileInformation',
  async (_, { dispatch, rejectWithValue }) => {
    const userType = getUserTypeFromToken()
    dispatch(startLoader())
    try {
      let response
      if (userType === 'business') {
        response = await userService.getProfileInformation()
      } else {
        response = await customerUserService.getProfileInformation()
      }

      dispatch(stopLoader())

      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// UPDATE DETAILS

export const updateDetails = createAsyncThunk(
  '/updateDetails',
  async (userCredentials: IUpdateDetails, { dispatch, rejectWithValue }) => {
    const userType = getUserTypeFromToken()
    dispatch(startLoader())
    try {
      let response
      if (userType === 'business') {
        response = await userService.updateDetails(userCredentials)
      } else {
        response = await customerUserService.updateDetails(userCredentials)
      }
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(getProfileInformation())
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(getProfileInformation())
      dispatch(stopLoader())

      return rejectWithValue(err)
    }
  },
)

// FORGET PASSWORD

export const forgetPassword = createAsyncThunk(
  '/forgetPassword',
  async (userCredentials: IForgetPasswordFormData, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await userService.forgetPassword(userCredentials)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      // dispatch(successToast(['Mail sent successfully']))
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// DELETE ACCOUNT

export const deleteAccount = createAsyncThunk(
  '/deleteAccount',
  async (userCredentials: IDeleteAccountFormData, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    const userType = getUserTypeFromToken()
    try {
      let response
      if (userType === 'business') {
        response = await userService.deleteAccount(userCredentials)
      } else {
        response = await customerUserService.deleteAccount(userCredentials)
      }
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      clearTokenFromCookie()
      // need to clear the redux state on account delete
      // currently if we clear the redux the user is navigating to /login and the success toast is also not displayed
      // Code for  Dispatch the reset state action
      // dispatch({ type: RESET_STATE })
      History.navigate('/')
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// UPDATE PROFILE IMAGE

export const updateProfileImage = createAsyncThunk(
  '/updateProfileImage',
  async (image: File, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    const userType = getUserTypeFromToken()
    try {
      let response
      if (userType === 'business') {
        response = await userService.updateProfileImage(image)
      } else {
        response = await customerUserService.updateProfileImage(image)
      }

      dispatch(successToast(handleMessages(response.message)))
      dispatch(getProfileInformation())
      dispatch(userDetails())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// DELETE PROFILE IMAGE

export const deleteProfileImage = createAsyncThunk(
  '/deleteProfileImage',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    const userType = getUserTypeFromToken()
    try {
      let response
      if (userType === 'business') {
        response = await userService.deleteProfileImage()
      } else {
        response = await customerUserService.deleteProfileImage()
      }

      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(userDetails())
      dispatch(getProfileInformation())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// Reset password

export const resetPassword = createAsyncThunk(
  '/resetPassword',
  async (newPasswordData: INewPasswordData, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await userService.resetPassword(newPasswordData)
      dispatch(stopLoader())
      History.navigate('/password-updated')
      return response
    } catch (err: any) {
      dispatch(errorAlert(handleMessages(err.message)))
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// Token expiry check after clicking Reset password button in mail
export const tokenExpiryCheck = createAsyncThunk(
  '/tokenExpiryCheck',
  async (tokenDetails: ITokenExpiryCheck, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await userService.tokenExpiryCheck(tokenDetails)

      if (response?.status === 'success') {
        const { email, token } = tokenDetails
        const encodedEmail = encodeURIComponent(email) // Encode email
        History.push(`/reset-password-page?email=${encodedEmail}&token=${token}`)
      }
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      History.navigate('/link-expired')

      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)
export const userPermissionDetails = createAsyncThunk(
  '/userPermissionDetails',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())

    try {
      const response = await userService.getUserPermissions()
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateForgotPasswordMailAddress: (state, data) => {
      state.forgotPasswordMailAddress = data.payload
    },
    updateUserDetails: (state, data) => {
      state.userData = data.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // User Details
      .addCase(userDetails.fulfilled, (state, action) => {
        state.userData = action.payload.data
        state.activePlan = action?.payload?.active_plan
      })
      .addCase(userDetails.rejected, (state, action) => {
        const responsePayload: any = action.payload
        if (
          responsePayload &&
          responsePayload.data &&
          responsePayload.data.last_subscription_end_date
        ) {
          state.userData.last_subscription_end_date =
            responsePayload.data.last_subscription_end_date
        }
        state.activePlan = initialState.activePlan
      })
      .addCase(getProfileInformation.fulfilled, (state, action) => {
        state.userProfileInformation = action.payload.data
      })
      .addCase(userPermissionDetails.fulfilled, (state, action) => {
        state.userData = action?.payload?.data
        state.activePlan = action?.payload?.data?.active_plan
      })
  },
})
export const { updateForgotPasswordMailAddress, updateUserDetails } = userSlice.actions

export const userState = (state: RootState) => state.user
const userReducer = userSlice.reducer
export default userReducer
