import { Box, Divider, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../_helpers/hooks'
import { isBusinessURL } from '../../../_helpers/routes'
import { Facebook2Icon, Logo2Icon, Twitter2Icon } from '../../../components/icons'
import Linkedin2Icon from '../../../components/icons/linkedin2Icon'

const PublicFooter = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const featureList = [
    translatedInput?.landingScreen?.contactFooterLink,
    translatedInput?.landingScreen?.faqFooterLink,
    translatedInput?.landingScreen?.resourcesFooterLink,
    translatedInput?.landingScreen?.seatBookingsFooterLink,
    translatedInput?.landingScreen?.qrBasedCheckInFooterLink,
    translatedInput?.landingScreen?.signageBoardsFooterLink,
    translatedInput?.landingScreen?.bookingManagementFooterLink,
    translatedInput?.landingScreen?.aboutUsFooterLink,
    translatedInput?.landingScreen?.termsOfServiceText,
    translatedInput?.privacyPolicyPage?.privacyPolicyTitle,
  ]
  const handleLinkClick = (item: string) => {
    const isBusiness = isBusinessURL()
    const baseBusinessUrl = `${window.location.protocol}//${process.env.REACT_APP_BUSINESS_URL}`

    let path
    switch (item) {
      case 'Contact':
        path = 'contact-us'
        break
      case 'FAQs':
        path = 'faq'
        break
      case 'Terms Of Service':
        path = 'termsofservice'
        break
      case 'Privacy Policy':
        path = 'privacy-policy'
        break
      default:
        path = 'coming-soon'
        break
    }

    const url = isBusiness ? `${baseBusinessUrl}/${path}` : path

    // If it's a business URL, replace with the full external link, otherwise navigate internally
    isBusiness ? window.location.replace(url) : navigate(url)
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.profileIcon.colorSix,

        px: { xs: '2rem', md: '7.5rem' },
      }}
    >
      <Box sx={{ margin: '0 auto', maxWidth: '87.5rem' }}>
        <Box display='flex' flexDirection='column' gap='1.763rem' textAlign='start' py='2.5rem'>
          <Box mt='1.775rem' onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
            <Logo2Icon fill={theme.palette.common.white} width='117' height='27' />
          </Box>
          <Box
            display='flex'
            flexDirection={{ xs: 'column', md: 'row' }}
            gap={{ xs: '1rem', lg: '3rem', xl: '5rem' }}
            justifyContent='space-between'
          >
            <Box sx={{ width: { xs: '100%', md: '11.125rem' } }} marginBottom='1.506rem'>
              <Typography sx={{ color: theme.palette.facilityList.colorOneHover }}>
                {translatedInput?.landingScreen?.qburstKochiAddress}
              </Typography>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: { xs: '1.5rem', lg: '3rem', xl: '5rem' },
              }}
            >
              {['left', 'middle', 'right'].map((side, index) => (
                <Box key={side}>
                  {featureList
                    .slice(
                      index * Math.ceil(featureList.length / 3),
                      index * Math.ceil(featureList.length / 3) + Math.ceil(featureList.length / 3),
                    )
                    .map((item, index) => (
                      <Box key={index} marginBottom='1.506rem'>
                        <a
                          style={{ textDecoration: 'none', cursor: 'pointer' }}
                          onClick={() => handleLinkClick(item)}
                        >
                          <Typography
                            sx={{
                              whiteSpace: 'nowrap',
                              color: theme.palette.facilityList.colorOneHover,
                            }}
                          >
                            {item}
                          </Typography>
                        </a>
                      </Box>
                    ))}
                </Box>
              ))}
            </Box>
            {['left', 'middle', 'right'].map((side, index) => (
              <Box
                key={side}
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  [theme.breakpoints.down(550)]: {
                    display: 'block',
                  },
                }}
              >
                {featureList
                  .slice(
                    index * Math.ceil(featureList.length / 3),
                    index * Math.ceil(featureList.length / 3) + Math.ceil(featureList.length / 3),
                  )
                  .map((item, itemIndex, arr) => (
                    <Box
                      key={itemIndex}
                      marginBottom={'1.506rem'}
                      onClick={() => handleLinkClick(item)}
                    >
                      <Typography
                        sx={{
                          color: theme.palette.facilityList.colorOneHover,
                          whiteSpace: 'nowrap',
                          wordBreak: 'break-word',
                        }}
                      >
                        {item}
                        {itemIndex !== arr.length - 1 && ' | '}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            ))}
            <Box display='flex' flexDirection='column'>
              <Box
                sx={{
                  color: theme.palette.facilityList.colorOneHover,
                  display: 'flex',
                  justifyContent: { md: 'end' },
                }}
              >
                {translatedInput?.landingScreen?.emailUsText}
              </Box>
              <Box
                sx={{
                  color: theme.palette.seat.dark,
                  fontSize: '1rem',
                  marginTop: '0.756rem',
                }}
              >
                <a
                  href='mailto:support@slashq.com'
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {translatedInput?.landingScreen?.supportSlashqEmail}
                </a>
              </Box>
              <Box display='flex' flexDirection='row' gap='0.569rem' marginTop='1.763rem'>
                <Box>
                  <a
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    href='https://www.facebook.com/slashQapp/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Facebook2Icon
                      width='41'
                      height='41'
                      fill={theme.palette.facilityList.colorDivider}
                      backgroundColor={theme.palette.common.white}
                    />
                  </a>
                </Box>
                <Box>
                  <a
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    href='https://twitter.com/slashQapp'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Twitter2Icon
                      fill={theme.palette.facilityList.colorDivider}
                      width='41'
                      height='41'
                      backgroundColor={theme.palette.common.white}
                    />
                  </a>
                </Box>
                <Box>
                  <a
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    href='https://www.linkedin.com/company/slashqapp'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Linkedin2Icon
                      fill={theme.palette.facilityList.colorDivider}
                      width='40'
                      height='41'
                      backgroundColor={theme.palette.common.white}
                    />
                  </a>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            border: 'solid 0.063rem',
            borderColor: theme.palette.profileIcon.colorFive,
            mx: { xs: '1rem', md: '7.5rem' },
          }}
        />
        <Typography
          sx={{
            color: theme.palette.facilityList.colorOneHover,
            py: '1.731rem',
            textAlign: 'center',
          }}
        >
          {translatedInput?.landingScreen?.copyRightText1}
        </Typography>
      </Box>
    </Box>
  )
}

export default PublicFooter
