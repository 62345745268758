/* eslint-disable camelcase */
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS, PLAN } from '../../_constants'
import { dateToDateInWords } from '../../_helpers/dateTime'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import { IPlans } from '../../_models/pricingInterface'
import ModalWithConfirmButtonRight from '../../components/common/modalWithConfirmButtonRight'
import { WarningIcon } from '../../components/icons'
import { useUserPermissionAuthorization } from '../../hooks'
import { logout } from '../../slices/authenticationSlice'
import {
  closePricingPage,
  closeProceedProModal,
  getProPaymentDetails,
  getPurchaseInfo,
  pricingData,
  trialPlanDetails,
} from '../../slices/pricingSlice'
import { userState } from '../../slices/userSlice'
import ProTrialInfoAlert from './chooseProPlanAlert'

const cardStyle = {
  width: '24.188rem',
  // height: '30.813rem',
  borderRadius: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: '1.5rem',
  textAlign: 'left',
}
interface iPrice {
  pricingDataInfo: IPlans[]
  isPublicPricingPage?: boolean
  canActivateTrial?: boolean
}
const PricingCards: FC<iPrice> = ({
  pricingDataInfo,
  isPublicPricingPage = false,
  canActivateTrial = false,
}) => {
  // PERMISSIONS

  const [isPermitted] = useUserPermissionAuthorization()

  const theme = useTheme()
  const dispatch = useAppDispatch()
  const pricingState = useAppSelector(pricingData)
  const { activePlan, userData } = useAppSelector(userState)
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const navigate = useNavigate()
  const { isProceedProModalOpen, newPlanData, isProfileNotUpdated, subscriptionPlanDetails } =
    pricingState
  const {
    message,
    has_payment,
    new_plan_starts_at,
    new_plan_ends_at,
    total_refund_amount,
    amount_payable,
    new_plan,
  } = newPlanData
  const { id, price, name, description } = new_plan

  const [showProTrialInfoAlert, setShowProTrialInfoAlert] = useState(false)
  const [planState, setPlanState] = useState<{
    selectedPlan: IPlans | null
    isTrialPlan: boolean
  }>({
    selectedPlan: null,
    isTrialPlan: false,
  })
  const [showAppleAccountPurchaseAlert, setShowAppleAccountPurchaseAlert] = useState(false)
  const handleChoosePlan = (paymentPlan: IPlans) => {
    const currentUserPlan = userData?.subscription?.slug
    const isBasicTrial = currentUserPlan === PLAN.BASIC_TRIAL
    const isBasicPlan =
      currentUserPlan === PLAN.MONTHLY_BASIC || currentUserPlan === PLAN.YEARLY_BASIC
    const isUpgradingToPro =
      paymentPlan?.slug === PLAN.MONTHLY_PRO || paymentPlan?.slug === PLAN.YEARLY_PRO

    // Show alert modal for upgrading from basic trial or basic plan to pro plan
    if ((isBasicTrial || isBasicPlan) && isUpgradingToPro) {
      setShowProTrialInfoAlert(true)
      setPlanState({ selectedPlan: paymentPlan, isTrialPlan: false })
    }
    // If the plan has a price adjustment, fetch Pro payment details
    else if (paymentPlan?.has_price_adjustment) {
      dispatch(getProPaymentDetails(paymentPlan?.id))
    } else if (subscriptionPlanDetails?.is_apple_iap) {
      setPlanState({ selectedPlan: paymentPlan, isTrialPlan: false })
      setShowAppleAccountPurchaseAlert(true)
    } else {
      dispatch(getPurchaseInfo(paymentPlan?.id))
    }
  }

  const handleTrialPlan = (trialPlan: IPlans) => {
    if (trialPlan?.slug === PLAN.MONTHLY_PRO || trialPlan?.slug === PLAN.YEARLY_PRO) {
      setShowProTrialInfoAlert(true) // Show alert for Pro trial
      setPlanState({ selectedPlan: trialPlan, isTrialPlan: true })
    } else {
      dispatch(trialPlanDetails(trialPlan?.trial_plan_id))
    }
  }

  const handleProAlertProceed = () => {
    const selectedPlan = planState?.selectedPlan

    // if selected plan is trial plan
    if (planState?.isTrialPlan && selectedPlan) {
      dispatch(trialPlanDetails(selectedPlan?.trial_plan_id))
      // if selected plan is pro plan
    } else if (!planState?.isTrialPlan && selectedPlan) {
      if (selectedPlan?.has_price_adjustment) {
        dispatch(getProPaymentDetails(selectedPlan?.id))
      } else {
        dispatch(getPurchaseInfo(selectedPlan?.id))
      }
    }
  }

  const handleProceedButton = () => {
    if (id && has_payment) {
      dispatch(getPurchaseInfo(id))
    }

    dispatch(closeProceedProModal())
  }
  const handleProceedButtonInAppleAccountPurchaseAlert = () => {
    const selectedPlan = planState?.selectedPlan
    if (selectedPlan) dispatch(getPurchaseInfo(selectedPlan?.id))
  }
  const handleCloseAppleAccountPurchaseAlert = () => {
    setShowAppleAccountPurchaseAlert(false)
    dispatch(closePricingPage())
  }
  // async function is added to ensure that the user is redirected to the "Contact Us" page after logging out, we need to make sure that the redirection logic happens after the logout action has been fully dispatched.
  // without async await the redirection flow is not working properly.
  // async await can be removed if we have a better solution.

  const handleClick = async () => {
    if (activePlan === true && !isProfileNotUpdated) {
      window.open('/admin/contact-us', '_blank')
    } else {
      // fix: Bug::(Expired Business account):: The user should be logged out or the pricing plan modal should not be shown to the user when redirected to the 'Contact us' page from the pricing plan modal[Web]
      await dispatch(logout())
      navigate('/contact-us')
    }
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          rowGap: '1.875rem',
          columnGap: '1.875rem',
          '& > :not(style)': {
            px: '1.5rem',
            pt: '1.188rem',
            pb: '1.625rem',
          },
        }}
      >
        {pricingDataInfo?.map((item, index) => (
          <Paper
            key={index}
            variant='outlined'
            sx={{
              ...cardStyle,
              ...(item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                ? {
                    backgroundColor: theme.palette.seat.contrastText,
                    borderColor: 'transparent',
                    color: theme.palette.common.white,
                    border: 'none',
                  }
                : { borderColor: `${theme.palette.publicPages.colorFive}` }),
              '&:hover': {
                boxShadow: `0 0.25rem 0.625rem 0 ${theme.palette.primary.light}`,
              },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              {(item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO) && (
                <Box
                  display={'flex'}
                  justifyContent={'end'}
                  sx={{
                    [theme.breakpoints.down(400)]: {
                      mb: 4,
                    },
                  }}
                >
                  <Box
                    sx={{
                      padding: '0.5rem 1.25rem',
                      borderRadius: '2.875rem',
                      backgroundColor: 'publicPages.contrastTextHover',
                      fontSize: '0.75rem',
                      fontWeight: 600,
                      color: 'common.white',
                      [theme.breakpoints.down(400)]: {
                        fontSize: '0.6rem',
                      },
                    }}
                  >
                    {translatedInput?.landingScreen?.recommendedText}
                  </Box>
                </Box>
              )}

              <Typography
                variant='h6'
                mb='0.75rem'
                mt={
                  item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                    ? '-1.5rem'
                    : '.5rem'
                }
                sx={{
                  [theme.breakpoints.down(400)]: {
                    fontSize: '1.5rem',
                  },
                }}
              >
                {item?.name}
              </Typography>

              <Typography
                variant='h2'
                fontSize='2.25rem'
                lineHeight='0.78'
                sx={{
                  [theme.breakpoints.down(400)]: {
                    fontSize: '1.5rem',
                  },
                }}
              >
                ₹{item?.price}{' '}
                {item?.invoice_period && (
                  <span
                    style={{
                      color:
                        item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                          ? theme.palette.common.white
                          : theme.palette.secondary.main,
                      fontSize: '0.938rem',
                      fontWeight: 'normal',
                    }}
                  >
                    {item?.invoice_period === 1 ? 'per ' : item?.invoice_period}{' '}
                    {item?.invoice_interval}
                  </span>
                )}
              </Typography>
              {(isPermitted([PERMISSIONS.SUBSCRIPTION_ADD]) || isPublicPricingPage) && (
                <>
                  <Button
                    variant='contained'
                    size='large'
                    disabled={item?.is_subscription_available ? false : true}
                    fullWidth
                    sx={{
                      mt: item?.trial_plan_id ? 3 : 5,
                      mb: '.1rem',
                      p: '.7rem',
                      backgroundColor:
                        item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                          ? theme.palette.common.white
                          : theme.palette.primary.main,
                      color:
                        item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                          ? theme.palette.primary.main
                          : theme.palette.common.white,
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor:
                          item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                            ? theme.palette.common.white
                            : theme.palette.primary.main,
                      },

                      '&.Mui-disabled': {
                        opacity:
                          item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                            ? 0.5
                            : 0.4,
                        backgroundColor:
                          item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                            ? theme.palette.info.main
                            : theme.palette.primary.main,
                        color:
                          item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                            ? theme.palette.seat.contrastText
                            : theme.palette.info.main,
                      },

                      [theme.breakpoints.down(400)]: {
                        fontSize: '0.8rem',
                      },
                    }}
                    onClick={() => handleChoosePlan(item)}
                  >
                    {item?.current_active_plan && !item?.is_subscription_available
                      ? translatedInput?.subscription?.currentActivePlanText
                      : translatedInput?.pricingPage?.subscribeNowBtn}
                  </Button>
                  {(item?.slug === PLAN.MONTHLY_PRO ||
                    item?.slug === PLAN.YEARLY_PRO ||
                    item?.slug === PLAN.MONTHLY_BASIC ||
                    item?.slug === PLAN.YEARLY_BASIC) &&
                    item?.trial_plan_id && (
                      <Button
                        variant='outlined'
                        size='large'
                        fullWidth
                        sx={{
                          mt: 3,
                          mb: '.5rem',
                          p: '.7rem',
                          borderColor:
                            item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                              ? theme.palette.common.white
                              : theme.palette.primary.main,
                          color:
                            item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                              ? theme.palette.common.white
                              : theme.palette.primary.main,
                          boxShadow: 'none',
                          '&:hover': {
                            borderColor:
                              item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                                ? theme.palette.common.white
                                : theme.palette.primary.main,
                          },
                          [theme.breakpoints.down(400)]: {
                            fontSize: '0.8rem',
                          },
                        }}
                        onClick={() => handleTrialPlan(item)}
                      >
                        {translatedInput?.landingScreen?.startTrialForFifteenDays}
                      </Button>
                    )}
                </>
              )}
              <List dense sx={{ flexGrow: 1, mt: 2 }}>
                {item?.features?.map((feature, idx) => (
                  <ListItem key={idx}>
                    <ListItemIcon>
                      <Box
                        sx={{
                          width: '0.625rem',
                          height: '0.625rem',
                          borderRadius: '50%',
                          backgroundColor:
                            item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                              ? 'common.white'
                              : 'profileIcon.colorDivider',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={feature?.name} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Paper>
        ))}
        <Paper
          variant='outlined'
          sx={{
            ...cardStyle,
            '&:hover': {
              boxShadow: `0 0.25rem 0.625rem 0 ${theme.palette.primary.light}`,
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderColor: 'publicPages.colorFive',
          }}
        >
          <Box>
            <Typography
              variant='h6'
              mb='0.75rem'
              mt='.5rem'
              sx={{
                [theme.breakpoints.down(400)]: {
                  fontSize: '1.5rem',
                },
              }}
            >
              {translatedInput?.pricing?.enterprise}
            </Typography>

            <Typography component='span' variant='subtitle2' color='secondary.main'>
              {translatedInput?.landingScreen?.enterprisePlanText1}{' '}
              <span style={{ color: theme.palette.common.black, fontWeight: 700 }}>
                {translatedInput?.landingScreen?.proText}
              </span>{' '}
              {translatedInput?.landingScreen?.enterprisePlanText2}
            </Typography>
            <Button
              variant='contained'
              size='large'
              fullWidth
              sx={{
                mt: canActivateTrial ? 10 : 2,
                mb: '.1rem',
                p: '.7rem',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                },
                [theme.breakpoints.down(400)]: {
                  fontSize: '0.8rem',
                },
              }}
              onClick={handleClick}
            >
              {translatedInput?.pricingPage?.contactUsBtn}
            </Button>
          </Box>
        </Paper>
      </Box>
      <ModalWithConfirmButtonRight
        showAlert={isProceedProModalOpen}
        title={`${translatedInput.subscription.proceedToText} ${name} ${translatedInput.subscription.planText}`}
        alertIcon={
          <WarningIcon
            fill={theme.palette.chart.contrastText}
            width='46'
            height='46'
            stoke={theme.palette.common.white}
          />
        }
        isDefaultButtonsVisible={true}
        focusConfirmButton={true}
        confirmButtonText={translatedInput.bookingManagement.yesProceedBtn}
        cancelButtonText={translatedInput?.common?.cancelBtn}
        onAlertConfirm={handleProceedButton}
        onAlertClose={() => dispatch(closeProceedProModal())}
      >
        <Box mb={'0.5rem'}>
          <Typography variant='subtitle1' color='secondary.main' textAlign='center'>
            {message}
          </Typography>
          <Box
            p={'0.625rem'}
            mt={'0.5rem'}
            sx={{
              backgroundColor: theme.palette.info.dark,
              borderRadius: '0.5rem',
            }}
          >
            {new_plan_starts_at && new_plan_ends_at && (
              <Typography
                variant='subtitle1'
                color='description.main'
                textAlign='left'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  lineHeight: 2,
                  mx: 1,
                  mb: 1,
                }}
              >
                <span>
                  {'Starts at'}: {dateToDateInWords(new_plan_starts_at)}
                </span>
                <span>
                  {'Ends at'}: {dateToDateInWords(new_plan_ends_at)}
                </span>
              </Typography>
            )}

            <Typography
              variant='h5'
              color='common.black'
              textAlign='left'
              sx={{ lineHeight: 2, mx: 1 }}
            >
              {translatedInput?.subscription?.summaryText}
            </Typography>

            <Typography
              variant='subtitle1'
              color='description.main'
              textAlign='left'
              sx={{ lineHeight: 2, mx: 1 }}
            >
              {`${description} ${translatedInput.subscription.amountText}`}: ₹{price}
            </Typography>
            <Typography
              variant='subtitle1'
              color='description.main'
              textAlign='left'
              sx={{ lineHeight: 2, mx: 1, mb: 1 }}
            >
              {translatedInput?.subscription?.refundAmountText}: ₹{total_refund_amount}
            </Typography>
            <Box
              display='flex'
              gap={1}
              sx={{
                backgroundColor: 'white',
                mx: 1,
                p: 1,
                borderRadius: '0.5rem',
              }}
            >
              <WarningIcon
                fill={theme.palette.iconColors.contrastText}
                width='20'
                height='20'
                stoke={theme.palette.common.white}
              />
              <Typography variant='subtitle1' color='description.main' textAlign='left'>
                {translatedInput?.subscription?.refundInfoText}
              </Typography>
            </Box>
            <Typography
              variant='subtitle1'
              color='common.black'
              textAlign='left'
              sx={{ lineHeight: 2, mx: 1, mt: 1 }}
            >
              {translatedInput?.subscription?.totalPayableAmountText}: ₹{amount_payable}
            </Typography>
          </Box>
        </Box>
      </ModalWithConfirmButtonRight>
      <ModalWithConfirmButtonRight
        showAlert={showAppleAccountPurchaseAlert}
        title={`${translatedInput.subscription.proceedToText} ${planState?.selectedPlan?.name} ${translatedInput.subscription.planText}`}
        alertIcon={
          <WarningIcon
            fill={theme.palette.chart.contrastText}
            width='46'
            height='46'
            stoke={theme.palette.common.white}
          />
        }
        isDefaultButtonsVisible={true}
        focusConfirmButton={true}
        confirmButtonText={translatedInput.bookingManagement.yesProceedBtn}
        cancelButtonText={translatedInput?.common?.cancelBtn}
        onAlertConfirm={handleProceedButtonInAppleAccountPurchaseAlert}
        onAlertClose={handleCloseAppleAccountPurchaseAlert}
      >
        <Box mb={'0.8rem'} mt={'0.8rem'}>
          <Typography variant='subtitle1' color='secondary.main' textAlign='center'>
            {translatedInput?.subscription?.purchaseFromAppleText}{' '}
            {translatedInput?.subscription?.wouldYouLikeText}
          </Typography>
        </Box>
      </ModalWithConfirmButtonRight>
      <ProTrialInfoAlert
        showAlert={showProTrialInfoAlert}
        setShowAlert={setShowProTrialInfoAlert}
        onAlertConfirm={handleProAlertProceed} // Handle the "Proceed" button
        isTrialPlan={planState?.isTrialPlan}
      />
    </>
  )
}
export default PricingCards
