import { Box, Button, ClickAwayListener, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useAppSelector } from '../../_helpers/hooks'
import CustomModal from '../../components/@extended/modal'
import { WarningIcon } from '../../components/icons'
import { pricingData } from '../../slices/pricingSlice'

interface IPricingAlertModal {
  showAlert: boolean
  onAlertConfirm: () => void
  modalContainerMaxWidth?: string
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>
  isTrialPlan: boolean
}

const ChooseProPlanAlert = (props: IPricingAlertModal) => {
  const { showAlert, setShowAlert, onAlertConfirm, modalContainerMaxWidth, isTrialPlan } = props
  const theme = useTheme()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const pricingState = useAppSelector(pricingData)
  const { subscriptionPlanDetails } = pricingState
  const closeAlertModal = (): void => {
    setShowAlert(false)
  }

  const handleProceedClick = () => {
    closeAlertModal()
    onAlertConfirm()
  }

  return (
    <CustomModal isOpen={showAlert}>
      <ClickAwayListener onClickAway={closeAlertModal}>
        <Grid
          container
          sx={{
            maxWidth: {
              sm: modalContainerMaxWidth || '25rem',
              md: modalContainerMaxWidth || '25rem',
              lg: modalContainerMaxWidth || '28rem',
            },
            minWidth: { sm: '25rem', md: '25rem', lg: '25rem' },
          }}
        >
          {' '}
          <Grid item xs={12}>
            <Stack justifyContent='center' alignItems='center'>
              <Box sx={{ height: '2.875rem', width: '2.875rem', mb: '1.5rem' }}>
                <WarningIcon fill={theme.palette.iconColors.contrastText} />
              </Box>
              <Typography variant='h4' textAlign='center' color='common.black'>
                {translatedInput?.pricingPage?.confirmPlan}
              </Typography>
              <Box py={3} maxWidth={'90%'}>
                {subscriptionPlanDetails?.is_apple_iap && (
                  <Typography variant='subtitle1' color='secondary.main' textAlign='center' mb={1}>
                    {translatedInput?.subscription?.purchaseFromAppleText}
                  </Typography>
                )}
                <Typography variant='subtitle1' color='secondary.main' textAlign='center'>
                  {isTrialPlan
                    ? translatedInput?.pricingPage?.chooseProTrialMsg
                    : translatedInput?.pricingPage?.upgradeBasicToProMsg}
                </Typography>
              </Box>
              <Box p={'0.625rem'} width={'100%'} mt={2}>
                <Grid container spacing={'1.25rem'}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      sx={{ width: '100%' }}
                      variant='outlined'
                      size='large'
                      onClick={closeAlertModal}
                    >
                      {translatedInput?.common?.cancelBtn}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      sx={{ width: '100%' }}
                      variant='contained'
                      size='large'
                      onClick={handleProceedClick}
                    >
                      {translatedInput?.bookingManagement?.yesProceedBtn}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </ClickAwayListener>
    </CustomModal>
  )
}

export default ChooseProPlanAlert
